import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import { Parallax, Background } from 'react-parallax';
import imgMobile from "../../assets/image/jpeg/needling.jpg";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'



const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;
const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: rgb(171, 142, 102);
font-size: 14px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;



const Secondtext = styled.h2`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #1a1a1a;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const ProfContentPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-lg-5 pb-lg-5">

         
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 pl-0 pr-0 ml-0 ml-0 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="What is non surgical rhinoplasty" className="img-fluid shadow rounded" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">
          
            <Text>

            Profhilo is a hyaluronic acid moisturising treatment that provides firm, plump, dewy, healthy and brighter looking skin through deep hydration. It is a very effective anti-ageing treatment, providing an overall lifting effect.<br /><br /> 

With one of the highest hyaluronic acid concentrations on the market, Profhilo treatment not only hydrates and stimulates the skin, but also remodels ageing and sagging tissue. It is clinically proven to improve skin hydration and texture from the inside out. <br /><br />

Profhilo London is released into specific, carefully mapped injection points on each side of the face, also known as bio-aesthetic points (BAP). <br /><br />

The BAP method includes 10 injections, 5 on each side, spread evenly across the face. The syringe is pre-filled with pure hyaluronic acid and can be administered within a few minutes.<br /><br /> 

 </Text>
           
            </Box>
          </Col>
        </Row>

        
   <Row>
            <Col>
            <Text>

            The face and neck are the most typical treatment regions, but it can also be used on the décolletage and hands, to effectively treat skin laxity. Men and women of various ages and skin types can benefit from this ground breaking treatment. <br /><br />

To begin your Profhilo journey, a course of two treatments, one month apart is required. The treatment is fully completed after two months, at which the hyaluronic acid has diffused into the tissue and safely settled into the dermal layer of your skin. The effect will last 6 months. To maintain the results, 1 treatment can be repeated every 6 months, depending on the quality of the skin, level of ageing, patients can choose to follow this maintenance treatment.<br /><br />

You could expect results in two-three weeks following your treatment, noticing an increase in luminosity and improvement in skin texture. Profhilo then continues to improve and rejuvenates your skin at a gradual steady rate and over time you will notice great improvement. <br /><br />

Profhilo is an award winning and one of the most advanced treatments available. Try the revolutionary Profhilo treatment now available at Dermamina London.<br /><br />
            </Text>
            <Secondtext>
How does Profhilo London work?
      </Secondtext>

<Text>
Profhilo treatment, an award winning 'beneath the skin' hyaluronic acid (HA) moisturising , uses revolutionary NAHYCO technology to help create a gel that can be injected into the skin to counteract skin laxity and improve and restore skin firmness. <br /><br />

Two different varieties of the ultimate hyaluronic acid are blended into a single compound using the NAHYCO technology. This means, the hybrid HA works together without being cross – linked, unlike most hyaluronic acid products that are cross linked via chemical agents.<br /><br />

Made up of both high and low molecular weight hyaluronic acid (HA), Profhilo is thermally stabilised, and when injected can produce long lasting dermal cell stimulation. The character and behaviour of the hyaluronic acid is altered during the thermal stabilisation procedure, allowing it to have this longer-lasting effect. <br /><br />

The low molecular weight component hydrates the skin and stimulates the development of new collagen and elastin, while the high molecular weight component gives support and acts as a scaffold. The pure hyaluronic acid remains in the skin for about 28 days due to heat stabilisation, but the steady release of HA stimulates four separate types of collagen and elastin for longer-lasting benefits. Despite its high HA content, it has a low inflammatory profile and clinically proven pro-healing benefits.<br /><br />

Profhilo lasts considerably longer below the skin and hydrates more deeply than ever before.<br /><br /> 

Profhilo is considered as a biomodelling agent as it remodels deep tissue and improve its elasticity and it also has hydrolift action due to this great water binding ability.<br /><br />


</Text>

<Secondtext>
Profhilo treatment procedure for the face:
</Secondtext>
<Text>
  
-	Appointment begins with a thorough consultation and assessment <br /><br /> 

-	The area to be treated is then cleansed<br /><br /> 

-	The pre-filled syringe is prepared. The syringe comes as 2mls, 1ml to be injected on each side <br /><br /> 

-	5 injection points on each side of the face is carefully and specifically mapped out on the face <br /><br /> 

-	The ultra-pure hyaluronic acid is injected into the specific points and gently massaged <br /><br /> 

</Text>

<Secondtext>
Why choose Profhilo treatment London?
</Secondtext>

<Text>
The beauty of Profhilo is that it does not change the structure of the face, the face will remain the same but the skin will look more youthful, plump, tight and firm.<br /><br /> 

Hyaluronic acid is a natural substance that is found in abundance in our skin and has a role in wound healing, hydration, and cell-to-cell communication. The ability of hyaluronic acid to effectively bind water like a sponge is one of its key properties. Profhilo stimulates collagen and elastin, pulling water to the deeper layers of the skin. <br /><br />

The gradual release of hyaluronic acid throughout this period stimulates four different forms of collagen and elastin. As a stabilised product, the activity on dermal  will last longer than that of a non-stabilised gel.<br /><br /> 

Significant tissue improvement occurs due to the stimulation. As a result, Profhilo is not only a skin booster but it also tightens and lifts the tissue.

</Text>
            </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default ProfContentPage;
