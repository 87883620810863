import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import {  Title, Section, Box, Text, Span } from "../../components/Core";


import { breakpoints } from "../../utils";


import imgC5 from "../../assets/image/jpeg/profhilo1.jpg";
import imgC8 from "../../assets/image/jpeg/profhilo3.jpg";
import imgC7 from "../../assets/image/jpeg/profhilo2.jpg";


import { device } from "../../utils";

import Himage from '../../assets/image/jpeg/home-bg2.jpg';

const Secti = styled.section`


position: relative;

background-color: #fffbf6;


@media screen and (max-width: 767px)
{

 }

`;

const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: url(${Himage}) no-repeat;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:0.1;


`;
const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: rgb(171, 142, 102);
font-size: 14px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;



const Secondtext = styled.h2`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #1a1a1a;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 26px;
  color: white;
  margin-bottom: 10px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 10px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: rgb(171, 142, 102, 0.3);
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: rgb(171, 142, 102);
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
  
    
    mx="3px"
   
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
    `}
  >
    <Text color="dark" mb={4}>
      {children}
    </Text>
    <Box className={`d-flex justify-content-between`}>
      <Box className="d-flex justify-content-center align-items-start" mr={3}>
        <img src={image} alt="Profhilo Before and After" className="img-fluid" />
      </Box>
      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
  </Box>
);

const NoseGalleryPage = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay:false,
   
    slidesToShow: 5,
    slidesToScroll: 5,
      speed: 500,
      cssEase: "linear",
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
    <Secti>
   
      <Section py={4}>
        <Container className="pt-lg-5 pb-lg-5">
        

            <Row className="text-center">
     
<Col lg="6" className="mb-3">
<img src={imgC5} alt="profhilo before and after" width="75%" />

</Col>

<Col lg="6" className="mb-3 mb-md-5">
<img src={imgC7} alt="profhilo  before and after" width="75%" />

</Col>

<Col lg="6" className="mb-3 mb-md-5">
<img src={imgC8} alt="profhilo  before and after" width="75%" /> </Col>



            </Row>
         
        </Container>
      </Section>
      </Secti>
    </>
  );
};

export default NoseGalleryPage;
